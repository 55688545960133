<template>
	<div class="root">
		<el-card>
			<h3>个人资料</h3>
			<el-divider></el-divider>
			<el-upload
				class="avatar-uploader"
				:action="'/api/blade-resource/oss/endpoint/put-file'"
				:headers="headers"
				:show-file-list="false"
				:on-success="handleAvatarSuccess"
				:before-upload="beforeAvatarUpload"
			>
				<el-image
					v-if="avatar"
					:src="avatar"
					fit="cover"
					class="avatar"
				/>
				<i v-else class="el-icon-plus avatar-uploader-icon"></i>
			</el-upload>

			<el-form
				ref="form"
				:model="form"
				label-position="right"
				label-width="100px"
			>
				<el-form-item label="姓名：">
					{{ form.realName }}
					<!-- <el-input
            disabled
            size="small"
            v-model="form.realName"
            placeholder="请输入姓名"
          ></el-input> -->
				</el-form-item>
				<!-- <el-form-item label="昵称：">
          <el-input disabled size='small' v-model="form.name" placeholder="请输入昵称"></el-input>
        </el-form-item> -->
				<el-form-item label="手机号：">
					{{ form.phone }}
					<!-- <el-input
            disabled
            size="small"
            v-model="form.phone"
            placeholder="请输入手机号"
          ></el-input> -->
				</el-form-item>
				<el-form-item label="性别：">
					{{ form.sexName }}
					<!-- <el-radio-group disabled v-model="form.sexName">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group> -->
				</el-form-item>
				<el-form-item label="学校：">
					{{ form.deptName }}
					<!-- <el-input
            disabled
            size="small"
            v-model="form.deptName"
            placeholder="请输入学校名称"
          ></el-input> -->
				</el-form-item>
				<el-form-item label="角色：">
					{{ form.roleName }}
					<!-- <el-input
            disabled
            size="small"
            v-model="form.roleName"
            placeholder="请输入身份"
          ></el-input> -->
				</el-form-item>
				<!-- <el-form-item label="学校：">
          <el-input disabled size='small' v-model="form.deptName" placeholder="请输入学校"></el-input>
        </el-form-item> -->
				<!-- <el-form-item>
					<el-button type="primary" size="small" @click="onSubmit"
						>保存</el-button
					>
				</el-form-item> -->
			</el-form>
			<el-button class="submit" type="primary" size="small" @click="onSubmit"
				>保存</el-button
			>
		</el-card>
	</div>
</template>

<script>
// import avatar from "@/assets/img/avatar.jpg";
import { updateAva_api } from '@/api/user.js'
import { sessionGet } from '@/utils/local.js'
export default {
	data() {
		return {
			form: this.$store.state.userInfo,
			avatar: this.$store.state.userInfo.avatar, // 显示的头像地址
			headers: {
				// 上传头像的请求头
				Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
				'Tenant-Id': '501654',
				'Blade-Auth':
					sessionGet('tk').token_type +
					' ' +
					sessionGet('tk').access_token,
			},
			avatarData: {}, // 上传头像后返回的头像地址数据
		}
	},
	methods: {
		// 保存更新
		onSubmit() {
			this.updateAva()
			this.form.avatar = this.avatar
			this.$store.commit('updateUserInfo', this.form)
		},
		// 更新头像
		async updateAva() {
			let { code, msg } = await updateAva_api({
				userId: sessionGet('userId'),
				avatar: this.avatarData.link,
			})
			if (code === 200) {
				this.$message({
					message: msg,
					type: 'success',
				})
			} else {
				this.$message.error(msg)
			}
		},
		// 上传头像成功回调
		handleAvatarSuccess(res, file) {
			this.avatar = URL.createObjectURL(file.raw)
			if (res.code === 200) {
				this.avatarData = res.data
			}
		},
		// 头像上传前
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || 'image/png'
			const isLt2M = file.size / 1024 / 1024 < 2
			this.headers = {
				// 上传文件前获取请求头，防止长时间未操作，使用token更新前的token
				Authorization: 'Basic cG9ydGFsOnBvcnRhbF9zZWNyZXQ=', // 网站标识‘portal’的base64编码后字符串
				'Tenant-Id': '501654',
				'Blade-Auth':
					sessionGet('tk').token_type +
					' ' +
					sessionGet('tk').access_token,
			}
			if (!isJPG) {
				this.$message.error('上传头像图片只能是 JPG/png 格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 2MB!')
			}
			return isJPG && isLt2M
		},
	},
}
</script>

<style lang="less" scoped>
.root {
	.el-card {
		margin-bottom: 20px;
		h3 {
			font-weight: 700;
		}
		.avatar-uploader {
			margin: 30px calc(50% - 51px);
			// transform: translateX(-50%);
			.avatar {
				width: 100px;
				height: 100px;
				border-radius: 50%;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 100px;
				line-height: 100px;
				border-radius: 50%;
				border: 1px dashed #d9d9d9;
			}
		}
		.el-form {
			margin-left: 25%;
			width: 50%;
			display: grid;
			grid-template-columns: repeat(2, 50%);
			.el-form-item {
				// &:last-child{
				//     width: 100%;
				// }
			}
		}
        .submit{
            display: block;
            margin: 0 auto;
        }
	}
}
</style>
